/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@fortawesome/fontawesome-free/css/all.css";
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600&display=swap");

a {
  color: rgba(0, 0, 0, 0.87);
}

a:hover {
  color: rgb(0, 147, 211) !important;
  text-decoration: none;
}

body::-webkit-scrollbar-track,
mat-sidenav.fpsidebar::-webkit-scrollbar-track,
mat-sidenav-content::-webkit-scrollbar-track,
.ui-table-scrollable-body::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px inset;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px inset;
}

body::-webkit-scrollbar,
mat-sidenav.fpsidebar::-webkit-scrollbar,
mat-sidenav-content::-webkit-scrollbar,
.fpscrolly::-webkit-scrollbar,
.ui-table-scrollable-body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

body::-webkit-scrollbar-thumb,
mat-sidenav.fpsidebar::-webkit-scrollbar-thumb,
mat-sidenav-content::-webkit-scrollbar-thumb,
.fpscrolly::-webkit-scrollbar-thumb,
.ui-table-scrollable-body::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  outline: #d8d8d8 solid 1px;
}

/********************Header********************/

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;
  /* higher than .mat-drawer-backdrop (overlay): z-index: 101 */
  background: #fff;
}

.headerHide {
  position: static;
}

::ng-deep .mat-drawer-backdrop {
  position: fixed !important;
}

/********************End of header*************/

.text-primary {
  color: rgb(0, 147, 211) !important;
}

.text-secondary {
  color: rgb(147, 195, 233) !important;
}

.text-light {
  color: rgb(245, 246, 247) !important;
}

.text-dark {
  color: rgb(179, 192, 211) !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.38) !important;
}



.border-primary {
  border-color: rgb(0, 147, 211) !important;
}

.border-secondary {
  border-color: #ebf5fb !important;
}

.border-dark {
  border-color: #d8d8d8 !important;
}

.border-muted {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.border-light {
  border-color: rgb(245, 246, 247) !important;
}

.border-search {
  border: 2px solid #d8d8d8 !important;
}

.border-default {
  border-color: #ced4da !important;
}

.btn-primary {
  background-color: rgb(0, 147, 211) !important;
  border-color: rgb(0, 147, 211) !important;
}

.btn-secondary {
  background-color: #d7eaf7 !important;
  border-color: #d7eaf7 !important;
}

.bg-primary {
  background-color: rgb(0, 147, 211) !important;
}

.bg-secondary {
  background-color: #d7eaf7 !important;
}

.bg-dark {
  background-color: #d8d8d8 !important;
}

.bg-light {
  background-color: rgb(245, 246, 247) !important;
}

.bg-info {
  background-color: rgb(225, 241, 252) !important;
}

.bg-default {
  background-color: #93c3e9 !important;
}

.bg-purple {
  background-color: #7460ee;
}

.bg-cyan {
  background-color: #26c6da;
}

.bg-yellow {
  background-color: #ffc107;
}

.bg-green {
  background-color: #28a745;
}

.bg-primary-gradient {
  background: rgba(0, 121, 201, 1);
  background: -moz-linear-gradient(top,
      rgba(0, 121, 201, 1) 0%,
      rgba(101, 172, 230, 1) 100%);
  background: -webkit-gradient(left top,
      left bottom,
      color-stop(0%, rgba(0, 121, 201, 1)),
      color-stop(100%, rgba(101, 172, 230, 1)));
  background: -webkit-linear-gradient(top,
      rgba(0, 121, 201, 1) 0%,
      rgba(101, 172, 230, 1) 100%);
  background: -o-linear-gradient(top,
      rgba(0, 121, 201, 1) 0%,
      rgba(101, 172, 230, 1) 100%);
  background: -ms-linear-gradient(top,
      rgba(0, 121, 201, 1) 0%,
      rgba(101, 172, 230, 1) 100%);
  background: linear-gradient(to bottom,
      rgba(0, 121, 201, 1) 0%,
      rgba(101, 172, 230, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0079c9', endColorstr='#65ace6', GradientType=0);
}

.rounded-10 {
  border-radius: 0.625rem !important;
}

.rounded-left-10 {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
}

.rounded-right-10 {
  border-top-right-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important;
}

.shadow-search {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.shadow-title {
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.16);
}

.font-weight-bold {
  font-weight: 500 !important;
}

.fpscrolly {
  overflow-y: auto;
}

.noscrollx {
  overflow-x: hidden;
}

.border-2 {
  border-style: solid;
  border-width: 2px;
}

/* Table Style */

.table-row-column {
  padding: 5px 10px;
  line-height: 1.2em;
  text-align: left;
}

.table-header .table-row-column {
  padding: 0 1px;
  font-weight: 500;
}

.table-header .table-row-column span {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.table-row-column span {
  padding: 8px 0;
  line-height: 2em;
}

.table-row-column span,
.table-row-column a {
  width: 100%;
}

/* Tab progress */

.tabProgress {
  position: relative;
}

.tabProgress>div {
  position: relative;
  z-index: 2;
}

.tabProgress:before {
  content: "";
  height: 1px;
  background-color: #93c3e9 !important;
  position: absolute;
  z-index: 1;
  top: 1.25rem;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
}

.tabProgress>div:first-child:before {
  left: 0;
}

.tabProgress>div:last-child:before {
  right: 0;
}

.tabProgress>div:first-child:before,
.tabProgress>div:last-child:before {
  content: "";
  height: 1px;
  background-color: #fff !important;
  position: absolute;
  z-index: 2;
  top: 1.25rem;
  width: calc(50% - 1.25rem);
}

.tabProgress .card-icon {
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #93c3e9;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  font-size: 1.3rem;
}

.tabProgress .card-icon.btn {
  padding: 0;
}

.tabProgress .card-text {
  color: rgba(0, 0, 0, 0.87);
  padding: 0.3rem;
  font-size: 1.1rem;
}

.tabProgress .card-icon i {
  line-height: 2.5rem;
}

/* new top progress */
.mobile-fixed-tabProgress-new {
  background: rgb(255, 255, 255);
  width: 100%;
  display: inline-block;

  z-index: 2;
}

.tabProgress.tabProgress-new {
  margin-top: 35px;
}

.tabProgress.tabProgress-new:before {
  top: 1rem;
  height: 2px;
}

.tabProgress.tabProgress-new>div:first-child:before,
.tabProgress.tabProgress-new>div:last-child:before {
  top: 0.9rem;
  width: calc(50% - 1rem);
  z-index: -1;
  height: 10px;
}

.tabProgress.tabProgress-new .card-icon {
  font-size: 1rem;
  border: 0;
  width: 2rem;
  height: 2rem;
  box-shadow: none;
}

.tabProgress.tabProgress-new .card-icon.rounded-border-new::after {
  content: "";
  width: 40px;
  height: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: #ffffff;
  top: 14px;
  z-index: -1;
  bottom: 0;
  border-radius: 50%;
  border: 2px solid rgb(0, 147, 211);
}

.tabProgress.tabProgress-new .card-icon i {
  line-height: 2rem;
}

.tabProgress.tabProgress-new .card-icon.submitted,
.tabProgress.tabProgress-new .card-icon.submitted.rounded-border-new::after {
  background-color: rgb(0, 147, 211);
}

.tabProgress.tabProgress-new .card-title-wrap {
  display: none;
}

.tabProgress.tabProgress-new .card-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0093d3;
  margin-top: 20px;
  display: inline-block;
}

.tabProgress.tabProgress-new .card-text.text-border-bootom {
  border-bottom: 2px solid #0093d3;
}

mat-horizontal-stepper[hide-header] .mat-horizontal-stepper-header-container {
  display: none;
}

.horizontal-stepper-new .mat-horizontal-content-container {
  padding-left: 0;
  padding-right: 0;
}

/* Material FP Theme */

.tabFPTheme .mat-horizontal-stepper-header .mat-step-icon,
.tabFPTheme .mat-horizontal-stepper-header .mat-step-icon-not-touched,
.tabFPTheme .mat-stepper-horizontal-line {
  display: none;
}

.tabFPTheme .mat-horizontal-stepper-header-container {
  align-items: stretch;
  padding: 0 0.6rem 0 0;
}

.tabFPTheme .mat-step-header {
  display: flex;
  border: none;
  position: relative;
  border-radius: 10px;
  padding: 0.7rem 2rem;
  height: auto;
  overflow: visible;
}

.tabFPTheme .mat-step-header:hover {
  background-color: #d8d8d8;
}

.tabFPTheme .mat-step-header[aria-selected="true"] {
  background-color: #d7eaf7;
}

.tabFPTheme .mat-step-header[aria-selected="false"]+.mat-vertical-content-container>.mat-vertical-stepper-content {
  display: none;
}

.tabFPTheme .mat-horizontal-content-container {
  padding: 0;
  overflow: inherit;
}

.tabFPTheme .mat-step-label {
  font-size: 1.1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
  min-width: auto;
}

.tabFPTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

/* Tab FP Theme */

.tabFPTheme .nav-tabs {
  margin: 0 -1px;
}

.tabFPTheme .nav-tabs .nav-item {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: none;
  margin: 0 1px;
  position: relative;
  display: flex;
  justify-content: center;
}

.tabFPTheme .nav-tabs .nav-item a {
  color: black;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  position: relative;
  z-index: 2;
  font-weight: 500;
  width: 100%;
}

.tabFPTheme .nav-tabs .nav-item a:hover {
  border: none;
}

.tabFPTheme .nav-tabs .nav-item a.active {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: rgb(225, 241, 252);
}

.tabFPTheme .card {
  border: none;
  margin: 0 0 0.5rem;
  display: block;
}

.tabFPTheme .card .btn .fa-check,
.tabFPTheme .mat-step-header .fa-check {
  color: #fff;
  background-color: rgb(0, 147, 211) !important;
}

.tabFPTheme .card .icon-fp {
  top: 0.6rem;
  left: -1.7rem;
  font-size: 1rem;
  height: 1.6rem;
  width: 1.6rem;
  line-height: 1.6rem;
}

.tabFPTheme .mat-step-header .icon-fp {
  position: absolute;
  top: 0.5rem;
  left: -0.9rem;
  font-size: 1rem;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
}

.tabFPTheme .card .icon-fp,
.tabFPTheme .mat-step-header .icon-fp {
  z-index: 2;
  content: "";
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  border: 1px solid #93c3e9 !important;
}

@media (min-width: 992px) {
  .tabFPTheme .card .icon-fp {
    position: absolute;
  }

  .tabFPTheme .mat-step-header .icon-fp {
    top: 0.3rem;
    left: -1.1rem;
    font-size: 1.3rem;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
  }
}

.tabFPTheme .card-header {
  background-color: #fff;
  padding: 0;
  border-bottom: 0px;
}

.tabFPTheme .card-header:first-child {
  border-top-left-radius: 0;
}

.tabFPTheme.tbFPPackagePUTheme .card-header:first-child {
  border-top-left-radius: 10px;
}

.tabFPTheme.tabFPThemeExt .card-header:first-child {
  border-top-left-radius: 10px;
}

.tabFPTheme .accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 10px;
}

.tabFPTheme .card-header .btn-link {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.7rem 0 0.7rem 0;
  line-height: normal;
  width: 100%;
  text-align: left;
  text-transform: none;
}

.tabFPTheme .card-header .btn-link:hover {
  text-decoration: none;
}

.tabFPTheme .btn .fa-chevron-down,
.tabFPTheme .btn .fa-chevron-up {
  position: absolute;
  right: 0.5rem;
  top: 0.8rem;
  font-size: 1.4rem;
  color: #5f6c77;
}

.tabFPTheme .card-body {
  padding: 0.5rem 0 0.5rem 0;
}

.tabFPTheme .fa-chevron-up,
.tabFPTheme .collapsed .fa-chevron-down {
  opacity: 1;
}

.tabFPTheme .fa-chevron-down,
.tabFPTheme .collapsed .fa-chevron-up {
  opacity: 0;
}

/**** Start Tab Employer Portal Page Theme ***/

.tabFPEmplrPrtlTheme .mat-horizontal-stepper-header .mat-step-icon,
.tabFPEmplrPrtlTheme .mat-horizontal-stepper-header .mat-step-icon-not-touched,
.tabFPEmplrPrtlTheme .mat-stepper-horizontal-line {
  display: none;
}

.tabFPEmplrPrtlTheme .mat-horizontal-stepper-header-container {
  align-items: stretch;
  justify-content: center;
  padding: 0;
  border-bottom: 3px solid #0093d3;
}

.tabFPEmplrPrtlTheme .mat-step-header {
  display: flex;
  border: 2px solid #d7eaf7;
  border-bottom: none;
  position: relative;
  border-radius: 15px 15px 0 0;
  padding: 0.7rem 2rem;
  height: auto;
  overflow: visible;
}

.tabFPEmplrPrtlTheme .mat-step-header:hover {
  background-color: #d8d8d8;
}

.tabFPEmplrPrtlTheme .mat-step-header[aria-selected="true"] {
  background-color: #0093d3;
  border: 2px solid #0093d3;
}

.tabFPEmplrPrtlTheme .mat-step-header[aria-selected="true"] .mat-step-label {
  color: #fff;
}

.tabFPEmplrPrtlTheme .mat-horizontal-content-container {
  padding: 0;
  overflow: inherit;
  border: 2px solid #d7eaf7;
  border-top: none;
}

.tabFPEmplrPrtlTheme .mat-step-label {
  font-size: 1.1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
  min-width: auto;
  text-transform: uppercase;
}

.tabFPEmplrPrtlTheme .mat-step-header .mat-step-label.mat-step-label-active {
  font-weight: 500;
}

/**** End Tab Employer Portal Page Theme ***/



::-webkit-file-upload-button {
  cursor: pointer;
}


input[type="file"] {
  cursor: pointer;
}

.custom-file-label {
  width: 100%;
  position: relative;
  border-radius: 10px !important;
  text-align: center;
}

.custom-file-label:after {
  display: none;
}

/** LOGIN / SIGNUP **/

.container-custom {
  background: url(./assets/images/group-classes-blur.jpg) center center no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding: 15px;
}

@media (min-width: 400px) {
  .container-custom:has(amplify-authenticator) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container-custom amplify-authenticator {
  position: relative;
  z-index: 2;
}

.amplify-heading {
  color: var(--amplify-colors-primary-80);
  text-align: center;
}

[data-amplify-qrcode] {
  margin: auto;
}

:root,
[data-amplify-theme] {
  --amplify-colors-primary: #0076a9;
  --amplify-colors-primary-80: #0076a9;
  --amplify-colors-neutral-90: #00131c;
  --amplify-components-button-border-radius: 10px;
  --amplify-components-fieldcontrol-border-radius: 10px;
  --amplify-components-authenticator-form-padding: 24px;
  --amplify-components-authenticator-footer-padding-bottom: 0;
  --amplify-components-authenticator-form-padding: 0;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.amplify-label {
  opacity: 0.6;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.amplify-input {
  height: 64px;
  border-color: #acdcf1;
  font-size: 16px;
  line-height: 24px;
}

amplify-sign-up .amplify-select[name="country_code"] {
  line-height: 48px;
  border-color: #acdcf1;
}

.amplify-input:focus {
  height: 64px;
  border-color: #acdcf1;
  box-shadow: none;
}

.amplify-button--fullwidth {
  height: 56px;
  margin-top: 10px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700 !important;
}

.amplify-button--link,
.amplify-button--default {
  color: var(--amplify-colors-primary-80);
  height: 56px;
  border-radius: 10px;
  border: 1px solid var(--amplify-colors-primary-80);
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
}

.amplify-button--link:hover,
.amplify-button--default:hover {
  background-color: #acdcf164;
}

button.amplify-field-group__control {
  border-left: none;
  border-color: #acdcf1;
  padding: 16px 24px;
}

button.amplify-field-group__control svg {
  width: 24px;
  height: 24px;
}

button.amplify-field-group__control:focus {
  outline: none;
  border: 1px solid #acdcf1;
  border-left: none;
  box-shadow: none;
}

.amplify-passwordfield .amplify-field-group__field-wrapper input {
  border-right: none;
}

.amplify-alert {
  margin-top: 8px;
}

.amplify-alert .amplify-button {
  border: none;
}

.amplify-alert .amplify-button:hover {
  border: none;
  background-color: transparent;
}

.amplify-tabs__panel {
  padding: 0;
}

[data-amplify-authenticator] [data-amplify-router] .amplify-tabs__list {
  border-top-width: 2px;
}

[data-amplify-authenticator] [data-amplify-router] .amplify-tabs__list .amplify-tabs__item {
  border-top-width: 4px;
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 500;
  height: 54px;
}

[data-amplify-footer] {
  padding: 0;
}

[data-amplify-footer] .amplify-button--link {
  height: 24px;
  font-size: 14px;
  margin-top: 24px;
  color: var(--amplify-colors-primary-80);
  border: none;
}

[data-amplify-footer] .amplify-button--link:hover {
  background-color: transparent;
  text-decoration: underline;
}

[data-amplify-form] {
  padding-bottom: 0 !important;
}

.container-custom [data-amplify-container] {
  background: #fff !important;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  border-radius: 10px;
}

.container-custom .login-register-section {
  width: 90%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  z-index: 2;
}

.container-custom form {
  width: 100%;
}

amplify-slot[name="sign-up-button"] button {
  margin-top: 0;
}

@media (max-width: 26rem) {
  [data-amplify-authenticator] [data-amplify-sign-up-errors] {
    font-size: 12px;
  }
}

/*form styles*/

.login-register-section fieldset {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 0.5rem 2rem;
  position: relative;
}

/*Hide all except first fieldset*/

#msform fieldset:not(:first-of-type) {
  display: none;
}

/*progressbar*/

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  z-index: 5;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 50%;
  float: left;
  position: relative;
  text-align: center;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 3px;
  margin: 0 auto 5px auto;
}

/*progressbar connectors*/

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/

/*The number of the step and the connector before it = green*/

#progressbar li.active:before,
#progressbar li.active:after {
  background: #27ae60;
  color: white;
}

.modal-backdrop {
  z-index: 900 !important;
}

.modal {
  z-index: 1000 !important;
}

.mat-autocomplete-panel {
  z-index: 1020 !important;
}

.cdk-overlay-container {
  z-index: 1020 !important;
}


html {
  font-size: 0.875rem;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.loading-shade {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-shade-global {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* do not group these rules */

*::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #6c757d;
  opacity: 1;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #6c757d;
  opacity: 1;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #6c757d !important;
  opacity: 1;
}

.table-none-th thead {
  display: none;
}

.mat-table {
  width: 100%;
}

.fp-title {
  position: relative;
  z-index: 2;
}

.fp-title h3 {
  font-size: 1.6rem;
  min-height: 35px;
}

.btn {
  text-transform: uppercase;
  border-width: 2px;
  border-radius: 10px;
}

.btnMenberSearch {
  padding: 0.304rem 0.75rem !important;
}

a.btn-primary {
  color: #fff !important;
}

.form-control {
  border-radius: 10px;
  border: 2px solid #ced4da;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.375rem 0.75rem !important;
}

.form-control:disabled {
  color: dimgrey !important;
}

@keyframes shimmer {
  to {
    background-position-x: 0%
  }
}

.faux-text-input-field {
  background-color: #e9ecef;
  opacity: 1;
  border-radius: 10px;
  border: 2px solid #ced4da;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.375rem 0.75rem;
}

.faux-text-input-field.loading {
  background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1s infinite linear;
}

.user-select-none {
  user-select: none;
}

label {
  color: rgba(0, 0, 0, 0.87);
}

.custom-control-label:before {
  border: 2px solid #ced4da !important;
}

.custom-control-label:after,
.custom-control-label:before {
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
}

.text-default {
  color: rgba(0, 0, 0, 0.87) !important;
}

.input-group {
  border-radius: 10px;
  border: 2px solid #ced4da;
  padding: 0 !important;
}

.input-group .form-control {
  height: calc(2.25rem - 2px);
}

.input-group .input-group-prepend~.form-control {
  padding-left: 0 !important;
}

.input-group .input-group-prepend {
  padding: 0 0.2rem 0 0.75rem;
}

.input-group>.input-group-append>.btn {
  padding: 0 0.75rem;
  border: none;
  margin-top: 1px;
  margin-right: 1px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-control {
  margin-bottom: 0.25rem;
}

.custom-control-label:before {
  border: 2px solid #93c3e9;
  background-color: #fff;
}

.custom-control-label {
  font-weight: normal;
  padding: 0 0 0 0.5rem;
}

.date-input-group .form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.date-input-group .form-control:disabled~.input-group-append>.btn {
  background-color: #e9ecef !important;
  opacity: 1;
  border: none !important;
  box-shadow: none !important;
}

.input-group .form-control,
.input-group .input-group-append .input-group-text,
.input-group .input-group-prepend .input-group-text {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
}

.input[type="file"][disabled] {
  cursor: default;
}

.input-group.disabled,
.disabled {
  background-color: #e9ecef;
  cursor: default;
}

.input-group.disabled button {
  display: none;
}

.input-group .form-control {
  border-radius: 10px;
}

.input-group .input-group-append .far,
.input-group .input-group-append .fas {
  color: rgb(0, 147, 211);
}

.input-group-prepend {
  align-items: center;
}

.form-group {
  margin-bottom: 0.75rem;
}

.form-group>div {
  display: flex;
  align-items: center;
}

.form-group .fas.fa-2x,
.mat-option .fas.fa-2x,
.fp-addedphoto-row .fas.fa-2x {
  font-size: 1.3rem;
}

.fpScannerTheme .fas.fa-2x,
.NoImage .fa-user-tie.fa-2x {
  font-size: 2rem;
}

.input-group.focused {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-radius: 10px;
}

.input-group .form-control:focus {
  color: inherit;
  background-color: inherit;
  border-color: transparent;
  box-shadow: none;
  border-radius: 10px;
}

.form-control[readonly]:focus {
  border-color: #ced4da;
  box-shadow: none;
}

.input-group.is-invalid.focused {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.input-group.is-invalid .input-group-prepend~.form-control {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent !important;
  height: 0px !important;
}

.mat-form-field-ripple {
  background-color: transparent !important;
  height: 0px !important;
}

.mat-form-field-wrapper {
  height: auto !important;
}

.mat-form-field {
  width: 100%;
}

.height1 {
  max-height: 14rem;
}

.height2 {
  max-height: 30rem;
}

.height100 {
  max-height: calc(100vh - 55px);
}

.h-100vh {
  height: 100vh;
}

.heightContent100 {
  height: calc(100vh - 124px);
}

.slctRoles p,
.slctRoles .custom-control,
.slctRoles a {
  padding: 0 0 0 0.75rem !important;
}

.slctRoles span {
  padding: 0 0.75rem 0 0;
  cursor: pointer;
}

.btn-calculate {
  line-height: 0.9rem;
  width: 100%;
  padding-top: 0.12rem !important;
  padding-bottom: 0.12rem !important;
}

.display-small {
  font-size: 0.75rem;
}

.tablecontainer .custom-control-input,
.slctRoles .custom-control-input {
  position: absolute;
  z-index: 5;
  opacity: 0;
  width: 1.2rem;
  height: 1.2rem;
  top: 0.2rem;
}

.tablecontainer .custom-control-label:after,
.tablecontainer .custom-control-label:before,
.slctRoles .custom-control-label:after,
.slctRoles .custom-control-label:before {
  width: 1.2rem;
  height: 1.2rem;
  top: 0.2rem;
  left: 0;
}

.tablecontainer .fas {
  font-size: 1.3rem;
}

.slctRoles .custom-control-label {
  padding-left: 2rem !important;
}

.slctSelectedRoles span {
  padding: 0 0 0 0.75rem;
  cursor: pointer;
}

.slctSelectedRoles p {
  padding: 0 0.75rem 0 0;
}

.slctRoles small {
  line-height: 1rem;
}

.ngb-tp .ngb-tp-input-container {
  position: relative;
  padding: 0 1.15rem 0 0;
  width: 4.6rem !important;
}

.ngb-tp-input-container .btn-link {
  position: absolute;
  right: 0;
  padding: 0;
  line-height: normal;
}

.ngb-tp-input-container .btn-link:first-child {
  top: 0;
}

.ngb-tp-input-container .btn-link:last-child {
  bottom: 0;
}

.ngb-tp-input-container .btn-link .chevron:before {
  border-width: 0.15em 0.15em 0 0;
}

.fp-profilephoto {
  height: 10rem;
}

.fp-profile .custom-file-label {
  right: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}

.custom-file .custom-file-input {
  cursor: pointer;
  font-size: 0;
  position: absolute;
  height: 100%;
}

.custom-file .custom-file-input[disabled] {
  cursor: default;
}

.hidden-file-input {
  display: none;
}

.fp-warning {
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  cursor: pointer;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.fp-packageItem {
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 0 1rem 1rem;
}

.fp-packageItem:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  content: "";
  border-radius: 14px;
  background-color: #0093d3;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.fp-packageItem:hover .fp-packagemid,
.fp-packageItem.selected-item .fp-packagemid {
  background: #93c3e9;
}

.fp-packageItem h5 {
  font-weight: normal;
  color: #fff;
  padding: 1.2rem 0;
  font-size: 2rem;
  text-transform: capitalize;
  position: relative;
  z-index: 4;
}

.fp-packageItem .text-price {
  font-weight: bold;
}

.fp-packagemid {
  background-color: #fff;
  border-radius: 14px;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  z-index: 4;
  padding: 1rem 0;
}

.fp-packagebtm {
  text-align: center;
}

.card-mbshiptype {
  color: #fff;
  height: 100px;
  border-radius: 5px;
  position: relative;
}

.card-mbshiptype i {
  font-size: 5em;
  opacity: 0.2;
  position: absolute;
  left: 1rem;
  bottom: 0%;
}

.membership-type {
  position: absolute;
  left: 62px;
  right: 0.5rem;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: #fff;
  text-transform: capitalize;
  font-weight: normal;
}

.mat-autocomplete-panel .mat-option .mat-option-text {
  display: flex;
  justify-content: space-between;
}

.mat-autocomplete-panel .mat-option {
  position: static;
  padding: 0 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  height: 35px;
  line-height: 35px;
  font-size: 1rem;
}

.address-autocomplete.mat-autocomplete-panel .mat-option {
  line-height: 1.5;
  white-space: normal;
  height: auto;
  font-size: 13px;
}

input.mat-input-element {
  margin-top: 0em;
  border: 2px solid #ced4da;
}

.popover {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border-radius: 10px;
}

.popover-body {
  padding: 0;
}

.popover-body .fa {
  font-size: 2rem;
}

.popover-warning .popover-body,
.popover-warning .popover a {
  color: #856404;
}

.popover-warning .popover {
  background-color: #fff3cd;
}

.popover-warning .bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-warning .bs-popover-right .arrow::after {
  border-right-color: #fff3cd;
}

.popover-error .popover-body,
.popover-error .popover a {
  color: #721c24;
}

.popover-error .popover {
  background-color: #f8d7da;
}

.popover-error .bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-error .bs-popover-right .arrow::after {
  border-right-color: #f8d7da;
}

.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0px;
  border-right-color: rgba(0, 0, 0, 0.2) !important;
}

.bs-popover-right .arrow::after {
  left: 2px;
}

.fpcard-title {
  width: 12rem;
  height: 12rem;
  padding: 0 1rem;
}

.fpcard a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.fpcard a:hover {
  transform: scale(1.05);
  z-index: 2;
}

.fpcard a:hover .fpcard-title {
  border-color: #007bff !important;
}

.tooltip-inner {
  text-align: left !important;
  background-color: #e1eef8;
  color: black;
}

.arrow::before {
  border-right-color: #e1eef8 !important;
}

.mat-sidenav-container {
  z-index: auto !important;
}

/******Style Table Grid ******/

table {
  width: 100%;
}

th.mat-sort-header-sorted {
  color: black;
}

.tablecontainer {
  padding: 0 0.5rem;
  overflow-x: auto;
  width: 100%;
}

.tablecontainer .mat-sort-header-button {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.mat-paginator {
  margin: 2px;
}

.mat-paginator .mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.tablecontainer th.mat-header-cell {
  padding-right: 0.75rem;
  text-transform: capitalize;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  background-color: #fff;
}

.tablecontainer td.mat-footer-cell {
  text-transform: capitalize;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

.tablecontainer th.mat-header-cell,
.tablecontainer td.mat-cell,
.tablecontainer td.mat-footer-cell {
  padding-left: 0.75rem;
}

.tablecontainer td.mat-cell a,
.mat-table a {
  cursor: pointer;
}

/*******************Freeze header row************************/

.height3 {
  max-height: 600px;
}

.fptablehead-scrolly {
  padding-right: 10px;
}

.fptablehead .mat-header-cell {
  border: none !important;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  background-color: #fff;
  padding-right: 0.2rem;
  min-height: auto;
}

.fptablefreezeheadrow .mat-row {
  display: flex;
}

.fptablefreezeheadrow .mat-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  padding-right: 0.5rem;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera 7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
  word-break: break-all;
}

.fptablefreezeheadrow tr.mat-row {
  min-height: 48px;
  height: auto !important;
}

/********** Style Table PrimeNG *********/

.fp-tablefrozen .ui-widget-header {
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.fp-tablefrozen .ui-table .ui-table-thead>tr>th,
.fp-tablefrozen .ui-table .ui-table-tfoot>tr>td {
  background-color: #fff;
  border-color: transparent;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.fp-tablefrozen .ui-table .ui-table-thead>tr>th {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.fp-tablefrozen .ui-table .ui-table-tbody>tr>td {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.fp-tablefrozen .ui-table .ui-table-thead>tr>th,
.fp-tablefrozen .ui-table .ui-table-tbody>tr>td,
.fp-tablefrozen .ui-table .ui-table-tfoot>tr>td {
  padding: 0 0 0 0.75rem;
}

.fp-tablefrozen .custom-control-input {
  position: absolute;
  z-index: 5;
  opacity: 0;
  width: 1.2rem;
  height: 1.2rem;
  top: 0.2rem;
}

.fp-tablefrozen .custom-control-label:after,
.fp-tablefrozen .custom-control-label:before {
  width: 1.2rem;
  height: 1.2rem;
  top: 0.2rem;
  left: 0;
}

/****************************************/

.cursordisplay {
  cursor: pointer;
}

.position-topright {
  right: -9%;
  top: -9%;
}

.position-bottomright {
  right: 0%;
  bottom: 0%;
}

.position-bottomleft {
  left: 0%;
  bottom: 0%;
}

.position-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.w-h-30px {
  width: 30px;
  height: 30px;
}

.thumb {
  margin-top: 15px;
  margin-bottom: 15px;
}

.image-gallery .fp-profile,
.image-gallery .fp-profilephoto {
  height: 15rem;
  max-height: 15rem;
  overflow: hidden;
}

.image-gallery .img-fluid {
  max-height: 15rem;
}

.fp-icon-1 {
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  display: block;
  text-align: center;
}

.fp-icon-1 i {
  vertical-align: middle;
}

.mat-toolbar {
  font-size: 1rem !important;
  background-color: transparent !important;
}

.mat-toolbar-multiple-rows {
  min-height: 56px !important;
  justify-content: center;
}

.is-invalid {
  border-color: #dc3545 !important;
}

/******************Tab*********************/

.tabFPTheme .mat-step-icon,
.mat-step-icon-not-touched {
  display: none;
}

.tabFPTheme .mat-vertical-content-container {
  margin: 0;
}

.tabFPTheme .mat-vertical-content {
  padding: 0 0 0 2rem;
}

.tabFPTheme .card:before {
  content: "";
  position: absolute;
  top: 0.8rem;
  bottom: -1rem;
  left: -0.9rem;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.12);
}

.tabFPTheme .card:last-child:before {
  border: none;
}

.nav-item .badge {
  position: relative;
  top: -6px;
}

.nav-link {
  color: #5f6c77;
  padding: 0.679rem 1rem !important;
  font-size: 1rem;
  font-weight: 500;
}

.nav-link i {
  width: 1.8rem;
}

.logo img {
  max-height: 50px;
}

.form-group .mat-spinner {
  position: absolute;
  right: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -0.6rem;
  z-index: 10;
}

.form-group .input-group .mat-spinner,
.form-group .input-group-extend .mat-spinner,
.form-group .input-group.input-group-exp .mat-spinner {
  right: 0.6rem;
  position: absolute;
}

.form-group .input-group.input-group-exp .form-control {
  border-radius: 0 10px 10px 0;
}

.mat-paginator .mat-paginator-container {
  min-height: auto;
}

.mat-paginator .mat-paginator-range-actions {
  min-height: auto;
}

.mat-paginator .mat-paginator-page-size {
  align-items: center;
}

.mat-paginator .mat-paginator-page-size-select {
  margin-top: 0;
}

.mat-paginator .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-paginator .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
  border-top: 0px;
}

.mat-paginator .mat-form-field-appearance-legacy .mat-form-field-label-wrapper {
  padding-top: 0;
}

.pointer-cursor {
  cursor: pointer;
}

.pos-right-top {
  right: 0%;
  top: 0%;
}

.modal-header {
  justify-content: center;
}

.modal-content {
  padding: 0 24px;
  border-radius: 10px;
  border-color: transparent;
  display: block !important;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.modal-body {
  padding: 0;
}

.fa-2x-ex {
  font-size: 1.3rem;
}

.wordbreak {
  -ms-word-break: break-all;
  word-break: break-word;
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  overflow-wrap: break-word;
}

/*remove icon in logo of employer page tab*/

#employertb7 .container,
.fp-profile .container,
#faclydetltb8 .container,
#facilityDetailstb1 .container {
  position: relative;
  width: 100%;
  padding: 0;
}

.facility-acttivity-item-checkbox .mat-checkbox>.mat-checkbox-layout {
  margin-bottom: 0;
}

.facility-acttivity-item-checkbox .mat-checkbox-checked.mat-accent .mat-checkbox-inner-container .mat-checkbox-background {
  background-color: #007bff;
}

.facility-acttivity-item-checkbox .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.facility-acttivity-item-checkbox .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #007bff;
}

#employertb7 .container,
.fp-profile .container {
  max-width: 400px;
}

.fp-profile .overlay,
.image-gallery .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.15);
}

.fp-profile .container:hover .overlay,
.image-gallery .fp-profile:hover .overlay {
  opacity: 1;
}

.img-fluid {
  width: 100%;
}

.link1 {
  text-decoration: underline;
}

.termconditions-dialog .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-open .termconditions-dialog.modal {
  overflow-y: hidden;
}

#fp-tcEndContent {
  height: 0.5rem;
}

.w-90 {
  width: 90%;
}

fieldset {
  width: 100%;
}

/* Lightbox */

.lightboxOverlay,
.lightbox {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.lb-outerContainer {
  background-color: transparent !important;
  width: 100% !important;
  height: 100% !important;
}

.lb-container {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lightbox .lb-image {
  height: auto !important;
  width: 100% !important;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0px !important;
  border: none !important;
}

.lb-dataContainer {
  width: 100% !important;
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 22px;
  z-index: 100;
}

.lb-nav a.lb-prev,
.lb-nav a.lb-next {
  width: 50%;
}

.maxWH1 {
  max-width: 183px;
  max-height: 183px;
}

.mat-autocomplete-panel.matautocomplete-wraptext .mat-option {
  min-height: 35px !important;
  height: auto !important;
  line-height: normal !important;
}

.display-0 {
  font-size: 0;
  height: 0;
}

.accordion>.card {
  overflow: visible !important;
}

/*end. remove icon in logo of employer page tab*/

@media (min-width: 576px) {
  .container-custom .login-register-section {
    width: 450px;
  }

  .w-sm-150px {
    width: 150px !important;
  }

  .w-230px {
    width: 230px;
  }

  .w-170px {
    width: 170px;
  }
}

@media (min-width: 768px) {
  .logo img {
    max-height: 100%;
  }

  .fp-title h3 {
    font-size: 1.75rem;
  }

  .btn-fp-emplr {
    width: 40%;
  }

  .fp-packageItem.selected-item {
    z-index: 2;
  }

  .fp-packageItem:hover {
    z-index: 3;
  }
}

/*  Add memer new changes */
.selectPackage-add-member-dialog .modal-dialog {
  width: 832px;
  max-width: 80vw;
  margin: 0 auto;
}

@media (min-width: 240px) {

  .fp-packageItem:hover:before,
  .fp-packageItem.selected-item:before {
    top: -14px;
    bottom: -14px;
    -webkit-box-shadow: 0 0 10px 1px #666;
    box-shadow: 0 0 10px 1px #666;
  }
}

@media (min-width: 992px) {
  .lightbox .lb-image {
    max-width: 100%;
    width: auto !important;
  }

  .max-w100 {
    max-width: 100%;
  }

  .membersignup-dialog .modal-dialog,
  .fp-modal-window .modal-dialog {
    max-width: 80%;
  }

  .MemberCancellationModal .modal-dialog {
    max-width: 550px;
  }

  .modal-dialog.modaldialog-md {
    max-width: 60%;
  }

  .w-40 {
    width: 40%;
  }

  header {
    position: static;
  }

  .fpsideContent {
    margin-top: 0px !important;
  }

  .fpsideCont-px-3 {
    padding: 0 3.3rem;
  }

  .fp-pl-1 {
    padding: 0 0 0 2.3rem;
  }

  .btn-fp {
    width: 25%;
  }

  .tabFPTheme .card-header {
    background-color: #d7eaf7;
    padding: 0;
    border-bottom: 0px;
    border-radius: 10px;
  }

  .tabFPTheme .mat-stepper-vertical {
    display: flex;
    position: relative;
    padding: 0 10px 0 0;
  }

  .tabFPTheme .mat-step {
    display: flex;
  }

  .tabFPTheme .mat-step-header {
    display: flex;
    border: 2px solid #d7eaf7;
    border-bottom: none;
    position: relative;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    padding: 0.5rem 1rem;
    height: auto;
    overflow: unset;
    min-width: 70px;
  }

  .tabAddEmployer .mat-step-header#cdk-step-label-0-3 {
    min-width: 125px;
  }

  .tabFPTheme .mat-vertical-stepper-header {
    max-height: none;
  }

  .tabFPTheme .mat-stepper-vertical-line::before {
    display: none;
  }

  .tabFPTheme .mat-vertical-content-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }

  .tabFPTheme .mat-vertical-content {
    padding: 0;
  }

  .tabFPTheme .mat-vertical-stepper-content {
    overflow: visible;
  }

  .tabFPTheme .card .icon-fp {
    top: 0.223rem;
    left: -1rem;
    font-size: 1.3rem;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
  }

  .tabFPTheme .card-header .btn-link {
    padding: 0.7rem 0 0.7rem 2rem;
  }

  .tabFPTheme .card-body {
    padding: 1.5rem 2rem;
  }

  .tabFPTheme .btn .fa-chevron-down,
  .tabFPTheme .btn .fa-chevron-up {
    right: 1rem;
    top: 0.6rem;
    font-size: 1.6rem;
  }

  .tabFPTheme .card:before {
    display: none;
  }

  .example-container .mat-sidenav {
    z-index: 100 !important;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.fa-minus-circle {
  color: red;
}

/* New Register Form */

.register-wrapper .form-control,
.form-wrapper-new .form-control {
  border: 1px solid #565757;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #565757;
}

.form-control--new {
  border: 1px solid #565757;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.register-wrapper .form-group,
.form-wrapper-new .form-group {
  margin-bottom: 28px;
}

/* common component */
.btn.btn-new {
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-transform: initial;
  font-family: "Montserrat", sans-serif;
  background-color: #0093d3 !important;
  color: #fff;
}

.btn.btn-new:hover {
  background-color: #bee3f0 !important;
  border-color: #bee3f0 !important;
}

.btn.btn-new.btn-mobile-100 {
  width: 100%;
}

.btn.btn-new.disabled,
.btn.btn-new:disabled {
  background-color: #d0d0d0 !important;
  border-color: #d0d0d0 !important;
}

.btn.btn-outline-new {
  min-height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  text-transform: initial;
  font-weight: 700;
  border-color: #0093d3;
  color: #0093d3;
  font-family: "Montserrat", sans-serif;
  box-shadow: none;
}

.btn.btn-outline-new:hover {
  border-color: #0093d3;
  color: #0093d3;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-outline-new:active {
  border-color: #0093d3 !important;
  background-color: #0093d3 !important;
  box-shadow: none;
}

.btn.btn-outline-new:hover .icon-previous {
  background-image: url(./assets/images/previous-h.png);
}

.icon-next,
.icon-previous,
.icon-previous-white,
.icon-upload,
.icon-filter {
  background-size: cover;
  display: inline-block;
  height: 15px;
  width: 15px;
  transform: translate(0, 0);
  background-repeat: no-repeat;
}

.icon-next {
  background-image: url(./assets/images/next.png);
  margin-left: 10px;
}

.icon-previous {
  background-image: url(./assets/images/previous.png);
  margin-right: 10px;
}

.icon-previous-white {
  background-image: url(./assets/images/previos-white.png);
  margin-right: 10px;
}

.icon-upload {
  background-image: url(./assets/images/upload-icon.png);
  margin-left: 10px;
  height: 18px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-filter {
  background-image: url(./assets/images/filter-icon.png);
  height: 24px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.mbr-registration-title h2 {
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  font-family: "Barlow", sans-serif;
}

.title-border {
  border-bottom: 2px solid #565757;
}

.registration-stepper-new {
  margin-bottom: 100px;
  margin-top: 30px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0093d3;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background-color: #0093d3;
}

.register-wrapper label,
.register-wrapper p,
.form-wrapper-new label,
.form-wrapper-new p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  color: #565757;
  font-size: 16px;
  line-height: 25px;
}

.title-wrapper {
  padding-top: 40px;
  padding-bottom: 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  background: rgb(255, 255, 255);
  width: 100%;
  z-index: 1;
}

.title-wrapper h1 {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  color: #0093d3;
}

/* input group new  */
.input-group.input-group-new {
  border: 1px solid #565757;
  border-radius: 5px;
}

.input-group.input-group-new .form-control {
  border: 0;
  height: calc(1.5em + 0.75rem);
}

.input-group.input-group-new .input-group-append .fas {
  color: #565757;
}

.input-group.input-group-new.is-invalid .input-group-append .fas {
  color: #dc3545;
}

.header-new {
  position: relative;
}

.fixed-mobile .btn {
  width: 50%;
}

.accordion-custom .accordion-custom--panel-header {
  font-family: "Montserrat";
}

.accordion-custom .mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.accordion-custom .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: transparent;
}

.accordion-custom .mat-expansion-panel .mat-expansion-panel-header {
  border-bottom: 2px solid #565757;
  border-radius: 0;
  height: auto;
  padding-left: 0;
}

.accordion-custom .mat-expansion-panel .mat-expansion-panel-header-title {
  font-family: "Barlow";
  font-style: normal;
  color: #565757;
}

.accordion-custom .mat-expansion-panel .mat-expansion-panel-header-title h2 {
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}

.accordion-custom .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding-left: 0;
  margin-top: 30px;
  padding-right: 0;
}

.user-profile {
  max-width: 294px;
}

/* Package Details*/

.package-details-wrapper .card-title {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}

.package-details-wrapper .package-info {
  font-family: "Montserrat", sans-serif;
}

.package-details-wrapper .package-info .package-price-wrapper {
  font-size: 24px;
  line-height: 25px;
  text-align: center;
}

.package-details-wrapper .package-info .package-price-wrapper .package-price {
  font-size: 30px;
}

.facility-title {
  border-bottom: 2px solid #565757;
}

/* medical qustion */

.medical-content-item {
  margin-top: 30px;
}

.medical-content-item:not(:last-child) {
  border-bottom: 1px solid #d0d0d0;
}

.medical-content-item .mediacal-content-wrapper {
  margin-bottom: 30px;
  max-width: 730px;
}

.medical-content-item .mediacal-content-wrapper p {
  margin-bottom: 0px;
}

.upload-img-wrapper .upload-img-wrapper-info {
  margin-top: 20px;
}

.upload-img-wrapper .upload-img-wrapper-info p {
  font-size: 14px;
  font-weight: 500;
}

.btn-upload {
  display: none;
}

.member-details-content {
  margin-top: 30px;
}

.member-details-content .table {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #565757;
}

.member-details-content .table thead {
  background-color: #bee3f0;
}

.member-details-content .table td,
.member-details-content .table th {
  border-top: 0;
}

.member-details-content .table tr:not(:last-child) td {
  border-bottom: 1px solid #565757;
}

.member-details-content .table thead th {
  border-color: #565757;
  border-width: 1px;
}

.popup-close {
  position: absolute;
  top: -11px;
  right: -8px;
  width: 12px;
  cursor: pointer;
}

@media (min-width: 576px) {
  .fixed-mobile .btn {
    width: auto;
  }
}

@media (min-width: 768px) {
  .btn-upload {
    display: flex;
  }

  .registration-stepper-new {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .package-details-wrapper .card-body {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-fixed-tabProgress-new {
    position: relative;
  }

  .user-profile {
    max-width: 100%;
  }

  /* commona component */
  .btn.btn-new.btn-mobile-100 {
    width: auto;
  }



  .horizontal-stepper-new .mat-horizontal-content-container {
    padding-left: 24px;
    padding-right: 24px;
  }


  .title-wrapper {
    box-shadow: none;
    border-bottom: 2px solid #d0d0d0;
    padding-bottom: 0%;
    position: relative;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .title-wrapper h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .tabProgress.tabProgress-new .card-title-wrap {
    display: block;
  }


  .btn.btn-outline-new:hover {
    color: #bee3f0;
    border-color: #bee3f0;
    background-color: transparent;
    box-shadow: none;
  }
}

@media (max-width: 576px) {
  .gm-style .gm-style-iw-c {
    max-width: 200px !important;
  }

  input {
    font-size: 16px !important;
  }
}